import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane, faTag, faUserFriends, faHeadset } from "@fortawesome/free-solid-svg-icons";
import "./Spanish.css";
import spanish1 from "../../Media/spain1.jpg";
import Booking from "../Bookings/Bookingslanding";
import Barcelona from '../../Media/Barcelona.jpg'
import Madrid from '../../Media/Madrid.jpg'
import Sevilla from '../../Media/Sevilla.jpg'
import agent from '../../Media/123123123.jpg'

const Spanish = () => {

    const handlePhoneClick = () => {
        // Google Ads conversion tracking for phone calls
        window.gtag('config', 'AW-16610863777/ED5wCNmkn9QZEKHV1vA9', {
          'phone_conversion_number': '(866) 410-4797'
        });
      
        // Initiate the phone call
        window.location.href = 'tel:+18664104797';
      };

      
  return (
<>
<section className="spaincta-container">
      {/* Header */}
      <div className="spaincta-header" onClick={handlePhoneClick} >Reservas de vuelos de aerolíneas</div>

      {/* Buttons Section */}
      <div className="spaincta-buttons" onClick={handlePhoneClick}>
        {["Nuevas Reservas", "Cambios", "Cancelaciones", "Servicio al Cliente", "Reembolsos", "Actualizaciones"].map(
          (text, index) => (
            <button key={index} className="spaincta-button" >
              {text}
            </button>
          )
        )}
      </div>

      {/* Phone Booking Info */}
      <div className="spaincta-info">
        <h3 className="spaincta-title">Reservas y cambios por teléfono</h3>
        <div className="spaincta-benefits">
          <span className="spaincta-benefit">✅ Pago flexible</span>
          <span className="spaincta-benefit">✅ Garantía de igualación de precios</span>
        </div>
        <hr className="spaincta-divider" />
      </div>

      {/* Call Button Section */}
      <div className="spaincta-call-section">
        <img
          src={agent} // Replace with actual image URL
          alt="Customer Support"
          className="spaincta-agent-img"
        />
        <p className="spaincta-wait-time">Sin espera: llamada contestada en 5 segundos</p>
        <a href="tel:+18664104797" onClick={handlePhoneClick} className="spaincta-call-link">
          ¡Haga clic para llamar!
        </a>
        <div className="spaincta-phone" onClick={handlePhoneClick}>📞 +1(866)410-4797</div>
      </div>

      {/* Footer */}
      <p className="spaincta-footer">
        Llame a nuestros expertos en reservas de vuelos y ahorre hasta un 60% en su reserva de vuelos.
      </p>
    </section>
<Booking/>
<div className="spanish-container">
      <section className="spanish-why-us">
        <h2 className="spanish-heading">¿Por qué nosotros?</h2>
        <div className="spanish-why-us-content">
          <img src={spanish1} alt="Golden Gate Bridge" className="spanish-image" />
          <div className="spanish-benefits">
            <div className="spanish-benefit">
              <FontAwesomeIcon icon={faPlane} className="spanish-icon" />
              <h3>El mejor asesor de viajes</h3>
            </div>
            <div className="spanish-benefit">
              <FontAwesomeIcon icon={faTag} className="spanish-icon" />
              <h3>Las mejores ofertas</h3>
            </div>
            <div className="spanish-benefit">
              <FontAwesomeIcon icon={faUserFriends} className="spanish-icon" />
              <h3>Reserva fácil</h3>
            </div>
            <div className="spanish-benefit">
              <FontAwesomeIcon icon={faHeadset} className="spanish-icon" />
              <h3>Servicio de atención al cliente</h3>
            </div>
          </div>
        </div>
      </section>

      <section className="spanish-book-flight">
        <h2 className="spanish-subheading">Reserva un vuelo en Low Airfare</h2>
        <p className="spanish-paragraph">
          Saludos a FlyNifty, su ventanilla única para todo lo relacionado con vuelos. FlyNifty está preparado para satisfacer sus
          necesidades de viaje, ya sea que esté planeando una escapada de fin de semana o unas vacaciones exóticas. Encontrar y
          comprar el vuelo ideal ahora es más fácil gracias a nuestra plataforma fácil de usar.
        </p>
        <ul className="spanish-features">
          <li>FlyNifty colabora con un gran número de aerolíneas para ofrecer una amplia gama de opciones de vuelos a precios bajos.</li>
          <li>
            El sitio web cuenta con numerosas opciones de búsqueda para permitir a los usuarios limitar su búsqueda por aerolíneas preferidas,
            duración del viaje, escalas y otros criterios.
          </li>
          <li>
            FlyNifty proporciona información precisa sobre los vuelos, incluyendo horarios de salida y llegada, duración del viaje, dirección de
            la escala y franquicia de equipaje.
          </li>
        </ul>

        <h3 className="spanish-subheading">Los mejores precios de billetes de avión</h3>
        <p className="spanish-paragraph">
          Nuestro objetivo es hacer que viajar sea simple. Puede encontrar los billetes de avión más asequibles, planificar su viaje y
          disfrutar de una experiencia inolvidable con FlyNifty.
        </p>

        <h3 className="spanish-subheading">Otros servicios</h3>
        <ul className="spanish-services">
          <li>Ofrecemos servicios personalizados para garantizar una experiencia de viaje fluida y cómoda.</li>
          <li>Expertos en planificación de viajes listos para atender sus necesidades exactas.</li>
        </ul>
      </section>

      <section className="spanish-destinations">
  <h2 className="spanish-subheading">Destino popular</h2>
  <div className="spanish-destination-grid">
    <div className="spanish-destination">
      <img
        src={Barcelona}
        alt="Barcelona"
        className="spanish-destination-image"
      />
      <p>Barcelona</p>
    </div>
    <div className="spanish-destination">
      <img
        src={Madrid}
        alt="Madrid"
        className="spanish-destination-image"
      />
      <p>Madrid</p>
    </div>
    <div className="spanish-destination">
      <img
        src={Sevilla}
        alt="Sevilla"
        className="spanish-destination-image"
      />
      <p>Sevilla</p>
    </div>
  </div>
</section>


<section className="spain-text-container">
        <h2 className="spain-text-heading">Reserva boletos de American Airlines con Vgroup Ventures</h2>
        <p className="spain-text-paragraph">
          ¿Planeas un viaje y necesitas reservar tu vuelo con American Airlines? No busques más: en <strong>Vgroup Ventures</strong>, nuestros expertos en viajes te ayudarán a reservar y encontrar tarifas excelentes que se ajusten a tu presupuesto.
        </p>
        <p className="spain-text-paragraph">
          Con acceso a más de 450 aerolíneas, ofrecemos ofertas en boletos de <strong>clase económica, ejecutiva y primera clase</strong> para American Airlines.
        </p>
        <h3 className="spain-text-subheading">Acerca de American Airlines</h3>
        <p className="spain-text-paragraph">
          American Airlines, con sede en Fort Worth, Texas, es una de las aerolíneas más grandes del mundo. Fundada en 1930, es miembro fundador de la <strong>OneWorld Alliance</strong>.
        </p>
        <h3 className="spain-text-subheading">Servicios de CheapOair</h3>
        <ul className="spain-text-list">
          <li>Vuelos de última hora</li>
          <li>Vuelos directos</li>
          <li>Escapadas de fin de semana económicas</li>
          <li>Vuelos multiconexión</li>
          <li>Tarifas de clase ejecutiva</li>
          <li>Tarifas de primera clase</li>
          <li>Vuelos nacionales e internacionales</li>
        </ul>
        <p className="spain-text-footer">¡Deja que Vgroup Ventures y CheapOair simplifiquen tu próxima aventura! 🌍✈️</p>
      </section>


    </div>
</>
  );
};

export default Spanish;
